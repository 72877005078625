import Layout from 'common/components/Layout'
import BioContent from 'containers/BioContent'
import BioTestimonials from 'containers/BioTestimonials'
import HeroBio from 'containers/HeroBio'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const KashBio = () => {
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        BIO_KASH {
          hero {
            title
            text
            linkedinUrl
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  quality: 100
                )
              }
            }
          }
          mainContent {
            shortVersion
            longVersion
            images {
              publicURL
            }
          }
          testimonials {
            name
            designation
            review
            avatar {
              publicURL
            }
          }
        }
      }
    }
  `)

  const { hero, mainContent, testimonials } = data.ertCreditJson.BIO_KASH

  return (
    <Layout hasContact={false}>
      <HeroBio content={hero} />
      <BioContent content={mainContent} />
      <BioTestimonials content={testimonials} />
    </Layout>
  )
}

export default KashBio
